import React from "react";
// import { auth } from "./firebase";
// import { useAuthState } from "react-firebase-hooks/auth";

import cookies from "../../../utils/cookies";
import userIcon from '../../../assets/user.png';
import adminIcon from '../../../assets/admin.png';
const Message = ({ message ,username}) => {

  // const [user] = useAuthState(auth);
  let user = {
    "uid": cookies.getUserID(),
    "email": cookies.getUsername(),
    "role": cookies.getUserRole(),
    "emailVerified": true,
    "displayName": cookies.getFullUserDisplayName(),
    "isAnonymous": false,
    "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c",
    "providerData": [
      {
        "providerId": "google.com",
        "uid": cookies.getUserID(),
        "displayName": cookies.getUserDisplayName(),
        "email": cookies.getUsername(),
        "phoneNumber": null,
        "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c"
      }
    ],
    "stsTokenManager": {
      "refreshToken": "AMf-vBxJdwOtJEFSDPmkgj3eagT2Ky-pX560MEClfO5acL5i379U9CZewAk4VaDuKgZnEGrfUJs5I376m-C03l8RNkZbd1QKYSmGH5kAZapYn9vhHRirtlyjxJx47fzfiByRK6J1rEwduFFZcQ91O3uaaIYdJNqCplj3jcsBRcfvD7ZSradpGIs0vM7rM6YvjFYP3v5sLjfCWdUXOKMBKzsgcxJVqRgf8-oW0dkOz0XTRV9HjOs-EW7I6r1c4v1SwSATkK8-ACOoqIAIhBovTgq0qt4i7i-GbqG_pTU07OWiasKji6MNtChNlhztqPRi7Y3IkArGnxaa56BDvSq_RIu4QTLy-h3PRihXZbwukGXsJ2XL69_QfmGCIh3UXCeQr6BLuHxGZe_riAEzKcqqV-AkUQI4Yzi93jgs2Fz59qJvpDcSJr8cR8-dyj8MXH7jMBltJOUDmI7w",
      "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE5MGFkMTE4YTk0MGFkYzlmMmY1Mzc2YjM1MjkyZmVkZThjMmQwZWUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoibmFuZGFuIGQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFjSFR0Zk0wdkowMGI5ak1WTW5SckV2Q0dKbWIxaEdHVG1sRE41ZVctQkZQeTB0PXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL215cGFyYWNodXRlLW5vdGlmaWNhdGlvbnMiLCJhdWQiOiJteXBhcmFjaHV0ZS1ub3RpZmljYXRpb25zIiwiYXV0aF90aW1lIjoxNjk0MTcxMDIyLCJ1c2VyX2lkIjoiVURsV0JFeG5EN1k3OFA3WVU5TXlVeEFYcFN2MSIsInN1YiI6IlVEbFdCRXhuRDdZNzhQN1lVOU15VXhBWHBTdjEiLCJpYXQiOjE2OTQxNzEwMjIsImV4cCI6MTY5NDE3NDYyMiwiZW1haWwiOiJuYW5kYW5kLmNvbUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwODAwNTA5NTQ0MTE4ODgzMjgzMCJdLCJlbWFpbCI6WyJuYW5kYW5kLmNvbUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.BPYsTubcOzPYfh164Ko3pPMRR65tIKIfEcowXWTUudClwLDz-JGn2GCuUNAs5Oqn5tDN1b4hCcSkMun5-qbRT_sQzq8B9LqVaUQnrb-28X6QpWmeQazjG_MPGs3RdWfx529MvnM3dkNituG6kHxXnIEVhQ2gqpNN6AS8MiEGKNYuQfb-gDIRirm5tOlJeLCjs8J_6019u2kE8YrbO5iAjxnrgw7NNJwBlZjEn6fQ3fxEUMVyIrJzomLcbxrY_wVBo0Zujw4qLgJJHds4tekdI8MOnQCjFrT1AlPPwiFgAPInUNTjz_l_qckLLLzlchSaHX3nP9BKYXqrKZsEFPEfSg",
      "expirationTime": 1694174622656
    },
    "createdAt": "1693995393108",
    "lastLoginAt": "1694171022347",
    "apiKey": JSON.parse(localStorage.getItem('firebaseConfig')).apiKey,
    "appName": "[DEFAULT]"
  };
  const savedRole = cookies.getUserRole();
  return (
    <div className={`chat-bubble mb-4 ${message.senderId.toString() === user.uid.toString()  ? "" : "left"}`}>
      <div className="chat-bubble_content mb-2">
        <img className="chat-bubble__left"
          src={`${savedRole === 'ROLE_SUPER_ADMIN' ? userIcon : userIcon}`}
          alt="user avatar" />
         {/* <pre>{JSON.stringify(message.senderId , null, 2) }</pre>
         <pre>{JSON.stringify(cookies.adminID , null, 2) }</pre> */}
        {/* <pre>{JSON.stringify(user.uid , null, 2) }</pre> */}
        <div className="chat-bubble__right">

          <div className="user-name"><small>
            {/* {username} */}
            
          {/* {`${ user.role === 'ROLE_SUPER_ADMIN' || message.senderId.toString()  === cookies.adminID.toString() ? 'Sandra - Customer Success Manager' : username?username:user.displayName}`} */}
          { message.senderName === "Admin Admin Account"
    ? "Admin - Customer Success Manager"
    : message.senderName 
    ||(user.role === 'ROLE_SUPER_ADMIN' || message.senderId.toString()  === cookies.adminID.toString() ? 'Sandra - Customer Success Manager' : username?username:user.displayName)}
      

            {/* <pre>{JSON.stringify(message.userData, null, 2)}</pre> */}
          </small></div>
          <div className="user-message">{message.text}</div>
        </div>
      </div>
      {/* <div className="d-flex fs-7">
       <small className="message-status text-primary" style={{fontSize:'10px'}}>ROLE:{message.role.replace('ROLE_','')}</small>
      </div> */}

      <div className="d-flex fs-7">

        {message?.createDateTime?(  <small className="message-time text-muted me-3">{new Date(message.createDateTime).toLocaleString()}</small>
     ):( <small className="message-time text-muted me-3"> {new Date(message.updateDateTime).toLocaleString()}</small>
     )}
        <small className="message-status text-success">{message.read?'read':'unread'}</small>
      </div>


    </div>
  );
};

export default Message;
