import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import CardContainer from '../CardContainer';
import TitleBlock from '../ModalTitleBlock';

import InputForm from './Form';
import useAuth from '../../hooks/useAuth';
import Logo_Icon from '../../assets/Logo_Icon.svg';
import cookies from '../../utils/cookies';
import './Login.scss';
let exportedUsername = ''; 
function Login() {
  const savedLogin = cookies.getRememberLogin();
  const [rememberLogin, setRememberLogin] = useState(savedLogin ? true : false);
  const [username, setUsername] = useState(''); // Add state for username
  const auth = useAuth();
  const navigate = useNavigate();
  const { isLoading, isError, data } = auth.status;
  const customErrorMsgs = {
    username: '',
    password: '',
    misc: '',
  };
  if (isError) {
    if (data === '401 Unauthorized - Unauthorized') {
      customErrorMsgs.username = 'Incorrect email.';
      customErrorMsgs.password = 'Incorrect password.';
    } else {
      //unexpected messages will appear under password field
      customErrorMsgs.password = data;
    }
  }

  //[]Get saved login from cookies, and pass to login form
  const validLogin = (form) => {
    /* Cannot have empty fields
    []Validate email?  input box seems to handle that nicely (unless want to customize)
    */
    if (!form.username || !form.password) return false;
    return true;
  };

  const handleSuccessfulLogin = useCallback(
    (user) => {
      if (rememberLogin) {
        if (user !== undefined) cookies.setRememberLogin(user, 30);
        //[] TODO look into why `undefined` is getting this far? Why is it being fired a 2nd time after login?
      } else {
        cookies.setRememberLogin('', 0);
      }
      navigate('/', { replace: true });
    },
    [navigate, rememberLogin]
  );

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    if (!validLogin(formData)) return console.error('LOGIN FAILED VALIDATION'); //[]Should notify why invalid
    const { username, password } = formData;
    const userToSubmit = username.toLowerCase().trim();
    const passToSubmit = password.trim();
    setUsername(userToSubmit); // Update the username state
    exportedUsername = userToSubmit; // Update the exported username
    /* useAuth hook handles API, Cookies and User State, 
      2nd param is callback for onSuccess (nav back to root, where 
      it will detect user and move to client
    */
    /* Call only, uses useAuth which also uses useAPI call to handle errors
   auth.status provides current state and data */
   
    auth.signin({ username: userToSubmit, password: passToSubmit }, handleSuccessfulLogin);
  };

  const toggleRememberLogin = () => {
    //[]Verify - Remember Email for 30 days
    setRememberLogin((prev) => !prev);
  };

  const navToForgotPass = () => {
    navigate('/forgot-password', { replace: true });
  };

  const loginCard = (
    <>
      <TitleBlock title="Log in to your Parachute." body="Please enter your details." />
      <hr />
      <InputForm
        handleSubmit={handleSubmit}
        savedLogin={savedLogin}
        isLoading={isLoading}
        errors={isError && customErrorMsgs}
      />
      <div className="loginOptions">
        <span className="rememberLogin" onClick={toggleRememberLogin}>
          <input
            type="checkbox"
            id="rememberLogin"
            name="rememberLogin"
            checked={rememberLogin}
            onChange={() => {
              /* Handled by parent but need prop for linting */
            }}
          />
          <label htmlFor="remeberLogin">
            <h2>Remember for 30 days</h2>
          </label>
        </span>
        <h2 id="forgotPassLink" onClick={navToForgotPass}>
          Forgot Password
        </h2>
      </div>
    </>
  );

  useEffect(() => {
    //Checks if logged in and redirects (also saved cookies)
    if (data?.token && auth.user) {
      handleSuccessfulLogin();
    }
  }, [data, auth.user, handleSuccessfulLogin]);

  return (
    <CardContainer id="loginCard">
      <img className="loginIcon" src={Logo_Icon} alt="Parachute Cash" />
      {loginCard}
    </CardContainer>
  );
}
export { exportedUsername };
export default Login;
