/* Hook that will tie into aiox and provide data, and status of requests
Intent is to DRY code across the app 
- axiosCall will need to be a full axios config object
{
  method: 'post',
  url: '/user/12345',
  data: {
  firstName: 'Fred',
  lastName: 'Flintstone'
}
*/

// import axios from 'axios'; //No Base URL
import axios from '../utils/axios';
import { useState, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import api from '../utils/api';
// import { cos } from '@amcharts/amcharts5/.internal/core/util/Math';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, isError: false, data: null };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload,
      };
    case 'FETCH_WAITING':
      return { ...state, isLoading: false, isError: false, data: null };
    default:
      throw new Error();
  }
};

const useApi = () => {
  const [axiosCall, setAxiosCall] = useState(null); //Config To be inserted in axios()
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: null,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {



        const result = axiosCall ? await axios(axiosCall) : null;
        if (axiosCall?.url === '/login' && result?.data.token && result?.data.userID) {
          if (result?.data.userID) {
            const role = await axios({
              method: 'get',
              url: `/users/${result.data.userID}/role`,
              headers: {
                Authorization: 'Bearer ' + result?.data.token,
              },
            });
            result.data['role'] = role.data.name;


            if (result?.data.token) {

              const config = await axios({
                method: 'get',
                url: `/firebase/getCredentials`,
                headers: {
                  Authorization: 'Bearer ' + result?.data.token,
                },
              });
              // console.log(config);
              localStorage.setItem('firebaseConfig', JSON.stringify(config?.data));
            }

          }
        }

        else if (axiosCall?.url === '/login') {
          const {showOtpScreen } = result.data;
          if (showOtpScreen) {
            // Navigate to OTP screen if showOTP is true
            navigate('/otp', { replace: true });
            return;
          }
          // if (result?.data.userID) {
          //   const role = await axios({
          //     method: 'get',
          //     url: `/users/${result.data.userID}/role`,
          //     headers: {
          //       Authorization: 'Bearer ' + result?.data.token,
          //     },
          //   });
          //   result.data['role'] = role.data.name;


          //   if (result?.data.token) {

          //     const config = await axios({
          //       method: 'get',
          //       url: `/firebase/getCredentials`,
          //       headers: {
          //         Authorization: 'Bearer ' + result?.data.token,
          //       },
          //     });
          //     // console.log(config);
          //     localStorage.setItem('firebaseConfig', JSON.stringify(config?.data));
          //   }

          // }
        }
        if (axiosCall?.url === '/validateOTP') {
          if (!result.data) {
            // Dispatch invalid OTP failure
            setErrorMessage('Invalid OTP'); 
            dispatch({ type: 'FETCH_FAILURE', payload: 'Invalid OTP' });
          // Stop further processing
          }
          const { token, userID } = result.data;
          if (result?.data.userID) {
            const role = await axios({
              method: 'get',
              url: `/users/${result.data.userID}/role`,
              headers: {
                Authorization: 'Bearer ' + result?.data.token,
              },
            });
            result.data['role'] = role.data.name;


            if (result?.data.token) {

              const config = await axios({
                method: 'get',
                url: `/firebase/getCredentials`,
                headers: {
                  Authorization: 'Bearer ' + result?.data.token,
                },
              });
              // console.log(config);
              localStorage.setItem('firebaseConfig', JSON.stringify(config?.data));
            }

          } else {
            // Handle OTP failure (optional)
            throw new Error('Invalid OTP');
          }
        }
        if (!didCancel) {
          if (!result) return dispatch({ type: 'FETCH_WAITING' });
          dispatch({ type: 'FETCH_SUCCESS', payload: result?.data || 'success' });
        }
      } catch (e) {
       
      
        const eMsg = e.response?.data;

        let errorToDisplay = eMsg ? `${eMsg.status} ${eMsg.error} - ${eMsg.message}` : `${e}`;

        if(errorToDisplay.includes('Cannot read properties of undefined')){ 
          errorToDisplay="The email and or password you entered is incorrect";
        }

        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: errorToDisplay });
        }
      }
    };
    fetchData();

    return () => {
      didCancel = true;
    };
  }, [axiosCall]);

  return [state, setAxiosCall,errorMessage];
};

export default useApi;
