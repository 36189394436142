import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import useAuth from '../../hooks/useAuth';
import { exportedUsername } from '../Login/Index';
function OTPValidation() {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, isError, data } = auth.status;
  useEffect(() => {
    if (isError) {
      // Assuming the error message is in auth.status.error or a similar field
      setError(data);
    }
  }, [isError, auth.status.error]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setError('Please enter the OTP');
      return;
    }

    try {
      console.log("exported username", exportedUsername);
      const response = await auth.validateOtp({ otp });

      console.log("response", response);
    } catch (err) {
      console.error('OTP Validation Error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="otp-validation">
      <h2>Enter OTP</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="otp">
          <Form.Label>OTP</Form.Label>
          <Form.Control
            type="text"
            value={otp}
            onChange={(e) => {
              const input = e.target.value;
              if (input.length <= 6) {
                setOtp(input);  // Only update the OTP if it's 6 or fewer characters
              }
            }}
            isInvalid={!!error || otp.length < 6}  // Show error if OTP is less than 6 characters
            maxLength={6}  // Restrict input to a maximum of 6 characters
          />
          <Form.Control.Feedback type="invalid">
            {error || 'OTP must be 6 digits long'}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type="submit" variant="primary">Submit OTP</Button>
      </Form>
    </div>
  );
}

export default OTPValidation;
