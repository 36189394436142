import React, { useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import useAuth, { AuthProvider } from './hooks/useAuth';
// import api from './utils/api';
import { App as CapacitorApp } from '@capacitor/app';


import LoginPage from './components/Login/Index';
import OTPValidation from './components/OTPValidation/OTPValidation';
import ForgotPass from './components/ForgotPass/Index';
import ClientView from './views/ClientView';
import FreemiumView from './views/FreemiumView';
import EmployeeView from './views/EmployeeView';
import ChangePasswordView from './views/ChangePasswordView';
import SettingsView from './views/SettingsView';
import AdminView from './views/AdminView';
import SupportView from './views/SupportView';
import ForumView from './views/ForumView';
import ForumDetailsView from './views/ForumDetailsView';
import CreateArticleView from './views/CreateArticleView';
import UpdateArticleView from './views/UpdateArticleView';
import ManageNotificationView from './views/ManageNotificationView';
import { PushNotifications } from '@capacitor/push-notifications'; // Import PushNotifications instead of CapacitorPushNotifications
import { FCM } from '@capacitor-community/fcm';
import ApplyView from './views/ApplyView';
// import GraphView from './views/GraphView';
import ChatViewer from './components/A-Dashboard/ChatViewer/Index';

import DebugView from './views/DebugView';
// import StatusBarBackground from './views/StatusBarBackground'
import Footer from './components/Footer/Index';
import Header from './components/Header/Index';

import { Plugins } from '@capacitor/core';

import api from './utils/api';
import { StatusBarStyle } from '@capacitor/status-bar';
import './styling/layout.scss'

import './styling/layout.scss';
import cookies from './utils/cookies';
// import firebase from "firebase/app"
// import 'firebase/messaging'; // Import messaging module separately


import { useState } from 'react';
import { Toast } from "@capacitor/toast";
// import api from './utils/api';
import { getPlatforms } from '@ionic/react';
import MxConnectView from './views/MxConnectView';
import UpdateApp from './components/UpdateApp/Index';
import { is } from 'date-fns/locale';
const { Device } = Plugins;

const { Platform } = Plugins;

const env = cookies.processenv.REACT_APP_ENV;
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDmA8eH1eruX565srrmozF7L1gkcl5avbA",
  authDomain: "myparachute-app.firebaseapp.com",
  projectId: "myparachute-app",
  storageBucket: "myparachute-app.appspot.com",
  messagingSenderId: "453273939315",
  appId: "1:453273939315:web:a91156a4bb80cbfea1e906",
  measurementId: "G-CD3CP18CY7"
};


export default function App() {
  const [appInfo, setAppInfo] = useState(null);

  try {
    // console.log(getPlatforms());
    // alert(getPlatforms());

    localStorage.setItem('platform', getPlatforms().toString());

  } catch (error) {

  }

  const nullEntry = []
  const [notifications, setnotifications] = useState(nullEntry);
  console.log('notifications', notifications);
  let auth = useAuth();
  let debugMode = false;
  if (cookies.environment === 'develop') {
    debugMode =
      // (cookies.processenv.REACT_APP_ENV === 'api.dev' && window.location.host.includes('192.168')) ||
      window.location.host.includes('192.168') || window.location.host.includes('localhost');
  }


  console.log('debugMode', debugMode);
  const savedRole = auth?.user?.role || cookies.getUserRole();
  try {
    const { StatusBar } = Plugins;
    // iOS only
    window.addEventListener('statusTap', function () {
      console.log('statusbar tapped');
    });
    StatusBar.setBackgroundColor({
      color: '#32D583',
    });
    // Display content under transparent status bar (Android only)
    StatusBar.setOverlaysWebView({ overlay: false });

    StatusBar.show();
    StatusBar.setStyle({
      style: StatusBarStyle.Dark
    });
    StatusBar.setOverlaysWebView({
      overlay: false
    });
  } catch (error) {
    console.log('error', error);
  }



  useEffect(() => {


    if (!debugMode) {
      console.log('Assigning head ID');


      try {
        /* Only capture analytics on develop branch (not local env) */
        window.heap.load(cookies.processenv.REACT_APP_HEAP_DEV_ID);

      } catch (error) {

      }

    } else {
      console.log('DebugMode', debugMode);
    }


    try {

      Device.getInfo().then((info) => {
        console.log('Device Info:', info);
        localStorage.setItem('device_info', JSON.stringify(info));
      });



      setTimeout(() => {
        try {
          let platform = JSON.parse(localStorage.getItem('device_info'));
          if (platform) {

            Device.getId().then((uuid) => {
              console.log('Device UUID:', uuid);
              let dID = uuid?.identifier;

              if (platform?.platform === 'ios') {
                dID = uuid;
              } else {
                dID = uuid?.identifier;
              }
              console.log('Device UUID:', dID);
              localStorage.setItem('pushDeviceToken', dID);
              // now you can subscribe to a specific topic
              FCM.subscribeTo({ topic: dID })
                .then(r => console.log(`subscribed to topic ` + dID))
                .catch(err => console.log(err));

            });
          }
        } catch (error) {
          console.log('error', error);
        }




      }, 3000);






    } catch (error) {
      console.log('error', error);
    }



  }, [debugMode]);




  const Logout = () => {
    let auth = useAuth();
    let navigate = useNavigate();

    const navToLogin = () => navigate('/login');

    auth?.signout(navToLogin);
    return;
  };
  const getRedirectionPath = (savedRole) => {
    switch (savedRole) {
      case 'ROLE_FREEMIUM':
        return '/freemium';
      case 'ROLE_EMPLOYEE':
        return '/employee';
      case 'ROLE_USER':
        return '/client';
      default:
        return '/client';
    }
  }
  const register = () => {


    try {
      console.log('Initializing HomePage');

      // Register with Apple / Google to receive push via APNS/FCM

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          console.log('Push Notification permission granted');
          PushNotifications.register();

        } else {
          // Show some error
          console.log('Push Notification permission denied');
        }
      });
      // PushNotifications.register();


      // PushNotifications.requestPermissions().then(result => {
      //   if (result.receive === 'granted') {
      //     // Permission granted, register for push notifications
      //     PushNotifications.register();
      //   }
      // });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token) => {
          console.log('token', token);
          console.log('New Notification Recieved!');
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          showToast(notification?.title);
          setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
          showToast(notification?.title);
          setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
        }
      );


      // now you can subscribe to a specific topic
      FCM.subscribeTo({ topic: 'my_parachute_develop' })
        .then(r => console.log(`subscribed to topic ` + 'my_parachute_' + cookies.environment))
        .catch(err => console.log(err));

      // Get FCM token instead of the APN one returned by Capacitor
      FCM.getToken()
        .then(r => console.log(`Token ${r.token}`))
        .catch(err => console.log(err));

    } catch (error) {
      console.log('error', error);
    }


  }
  const isAppVersionSupported = (appVersion, platform, majorVersionChanges) => {
    console.log('appVersion', appVersion);
    console.log('platform', platform);
    console.log('majorVersionChanges', majorVersionChanges);
    if (platform === 'android') {
      for (const change of majorVersionChanges) {
        if (change.supported_android_versions.includes(appVersion) || change.supported_iso_versions.includes(appVersion)) {
          return true; // App version is supported for major version change
        }
      }
    }
    if (platform === 'ios') {
      for (const change of majorVersionChanges) {
        if (change.supported_iso_versions.includes(appVersion)) {
          return true; // App version is supported for major version change
        }
      }
    }


    return false; // App version is not supported for major version change
  };
  let navigate = useNavigate();
  useEffect(() => {
    try {

      const fetchAppInfo = async () => {

        try {
          const info = await CapacitorApp.getInfo();
          setAppInfo(info);
          localStorage.setItem('installed_app_info', JSON.stringify(info));
          let platform = JSON.parse(localStorage.getItem('device_info'));
          if (platform) {
            let data = {};


            api.appMetadata(data).then(resp => resp?.data)
              .then(res1 => {

                console.log('device_info', platform);
                console.log('appMetadata', res1);

                if (!isAppVersionSupported(info.version, platform?.platform, res1?.major_version_change)) {
                  //navigate to /update-app


                  console.log('navigate to /update-app');
                  navigate('/update-app');
                }

              }).catch(err => {
                console.log('appMetadata error', err);
              });
          }
        } catch (error) {
          console.log('error', error);
        }


      };

      fetchAppInfo();

    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {

    try {

      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              showToast('Push Notification permission denied');
            }
            else {
              showToast('Push Notification permission granted');
              register();
            }
          });
        }
        else {
          register();
        }
      });

    } catch (error) {
      console.log('error', error);
    }

  }, [])



  const showToast = async (msg) => {
    try {
      await Toast.show({
        text: msg
      })
    } catch (error) {
      console.log('error', error);
    }

  }


  console.log('hi');

  return (
    <AuthProvider>
      {/* AuthProvider / contextProvider */}
      <Routes>
        <Route path="/apply" element={<ApplyView />} />

        <Route path="/mx-connection" element={<MxConnectView />} />

        <Route path="/preApproved" element={<ApplyView />} />

        <Route element={<Layout debugMode={!!debugMode} adminMode={auth?.user?.role === 'ROLE_SUPER_ADMIN'} />}>
          {debugMode && <Route path="/debug" element={<DebugView />} />}
          <Route path={'/users/changePassword'} element={<ChangePasswordView />} />
          {/* /users/changepassword is the route given in the forgot password email. */}
          <Route path={'/users/activate'} element={<ChangePasswordView newAccount />} />

          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/otp" element={<OTPValidation />} />
          <Route path="/forgot-password" element={<ForgotPass />} />
          <Route path="/update-app" element={<UpdateApp />} />

          <Route
            exact
            path="/admin/inspirations"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView inspiration />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/quiz"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView quizzes />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/tree"
            element={
              <RequireAuth options={{ requireAdmin: true }}>

                <AdminView treeViewer />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/admin/quizStreaks"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView streakViewer />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/admin/graphView"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView graphView />
              </RequireAuth>
            }
          />


          <Route
            exact
            path="/admin/CIFViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView cIFViewer />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/admin/QuizSubmitViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView quizSubmitViewer />
              </RequireAuth>
            }
          />


          <Route
            exact
            path="/admin/MoodsViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView moodsViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/InspirationsViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView inspirationsViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/treeViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView treeViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/EngagementsViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView engagementsViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/UserAnalyticsViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView userAnallyticsViewer />
              </RequireAuth>
            }
          />
           <Route
            exact
            path="/admin/ReportsViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView reportsViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/SelfAssessmentViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView selfAssessmentViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/AddQuiz"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView addQuiz />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/AddManualQuiz"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView addManualQuiz/>
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/chatRoomsViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView chatRoomsViewer />
              </RequireAuth>
            }
          />


          <Route
            exact
            path="/admin/MxViewer/:id"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView mxViewer />
              </RequireAuth>
            }
          />


          <Route
            exact
            path="/admin/QuizViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView quizViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/CashBackViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView cashBackViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/CIFViewerRD"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView cIFViewerRD />
              </RequireAuth>
            }
          />
          {/* <Route
            exact
            path="/admin/ApplicantViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView applicantViewer />
              </RequireAuth>
            }
          /> */}

          <Route
            exact
            path="/admin/ApplicantViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView applicantViewerRD />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/admin/TradeViewer/:id"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView tradeViewer />
              </RequireAuth>
            }
          />
          {/* TransactionViewer*/}
          <Route
            exact
            path="/admin/TransactionViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView transactionviewerRD />
              </RequireAuth>
            }
          />



          <Route
            exact
            path="/admin/TimelineViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView timelineViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/userActivation"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView userActivation />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/app-graph"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView graphView />
              </RequireAuth>
            }
          />
          {/* <Route path="/admin/app-graph" element={<graphView />} /> */}
          <Route
            path="/admin/dashboardViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView dashboardViewer />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/lendersAPI"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView lendersAPI />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/admin/userViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView userViewer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/chatViewer"
            element={

              <ChatViewer />
            }
          />
          <Route
            exact
            path="/admin/assessmentViewer"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView assessmentViewer />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/"
            element={
              <RequireAuth options={{ requireAdmin: true }}>
                <AdminView />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/client/settings/accounts/*"
            element={
              <RequireAuth>
                <SettingsView initMode={'accounts'} />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/client/settings"
            element={
              <RequireAuth>
                <SettingsView initMode={'root'} />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/client/support"
            element={
              <RequireAuth>
                <SupportView />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/client/forum"
            element={
              <RequireAuth>
                <ForumView forumView={'root'} />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/client/forum-details/*"
            element={
              <RequireAuth>
                <ForumDetailsView forumDetailsView={'root'} />
              </RequireAuth>
            }
          />


          <Route
            exact
            path="/client/create-article"
            element={
              <RequireAuth>
                <CreateArticleView createArticleView={'root'} />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/client/update-article"
            element={
              <RequireAuth>
                <UpdateArticleView updateArticleView={'root'} />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/client/manage-notification"
            element={
              <RequireAuth>
                <ManageNotificationView manageNotificationView={'root'} />
              </RequireAuth>
            }
          />

          <Route
            path="/client/*"
            exact
            element={
              <RequireAuth>
                <ClientView />
              </RequireAuth>
            }
          />

          <Route
            path="/freemium/*"
            exact
            element={
              <RequireAuth>
                <FreemiumView />
              </RequireAuth>
            }
          />

          <Route
            path="/employee/*"
            exact
            element={
              <RequireAuth>
                <EmployeeView />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<Navigate to={getRedirectionPath(savedRole)} />} />

        {/* <Route path="*" element={<Navigate to="/client" />} /> {/* //Catch all route */}
      </Routes>
    </AuthProvider >
  );
}

function Layout({ debugMode, adminMode }) {
  return (
    <>
      {/* <StatusBarBackground style={{backgroundColor:'midnightblue'}}/> */}

      <div className="appContainer">
        {/* appContainer div could be a fragment since it is in #root */}
        <Header debugMode={!!debugMode} />
        <main className={adminMode ? 'adminMain' : ''}>
          <Outlet /> {/* Outlet is similet to {children} */}
        </main>
        <Footer />
      </div>
    </>
  );
}

function RequireAuth({ children, options }) {
  // const RequireAuth = async ({ children, options }) => {
  let auth = useAuth();
  const role = auth?.user?.role;
  if (!auth.user?.jwt) {
    console.log('FAILED AUTH re-directing to login');
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    /* Currently doesn't use the from... */

    // return <Navigate to="/login" state={{ from: location }} replace />;

    return <LoginPage />;
    /* /login redirects to /client and pushes into this function
    behaviour is okay except for browser url, ideally it would say /login (just nice to have) 
    issue appeared that useAuth context wasn't remembering when a client was logged in...
    */
  }
  if (options?.requireAdmin === true) {
    const isAdmin = role === 'ROLE_SUPER_ADMIN';
    // const isFreemium = role === 'ROLE_FREEMIUM';


    //   console.log('ADMIN ROUTE > isAdmin', isAdmin);
    if (!isAdmin) {
      switch (role) {
        case 'ROLE_FREEMIUM':
          return <FreemiumView />;
        case 'ROLE_EMPLOYEE':
          return <EmployeeView />;
        case 'ROLE_USER':
          return <ClientView />;
        default:
          return <ClientView />;
      }
    }
  }
  if (role === 'ROLE_SUPER_ADMIN') {
    console.log('isSuperAdmin...');
    if (children.props.initMode) return <SettingsView initMode={'root'} isAdmin={true} />;
    if (children.props.forumView) return <ForumView forumView={'root'} isAdmin={true} />;

    if (children.props.forumDetailsView) return <ForumDetailsView forumDetailsView={'root'} isAdmin={true} />;
    if (children.props.createArticleView) return <CreateArticleView createArticleView={'root'} isAdmin={true} />;

    if (children.props.updateArticleView) return <UpdateArticleView updateArticleView={'root'} isAdmin={true} />;
    if (children.props.manageNotificationView) return <ManageNotificationView manageNotificationView={'root'} isAdmin={true} />;



    if (children.props.lendersAPI) return <AdminView lendersAPI={true} />;
    if (children.props.graphView) return <AdminView graphView={true} />;
    if (children.props.inspiration) return <AdminView inspiration={true} />;
    if (children.props.quizzes) return <AdminView quizzes={true} />;
    if (children.props.userActivation) return <AdminView userActivation={true} />;
    if (children.props.dashboardViewer) return <AdminView dashboardViewer={true} />;
    if (children.props.userViewer) return <AdminView userViewer={true} />;
    if (children.props.chatViewer) return <AdminView chatViewer={true} />;



    if (children.props.streakViewer) return <AdminView streakViewer={true} />;
    if (children.props.graphView) return <AdminView graphView={true} />;
    if (children.props.cIFViewer) return <AdminView cIFViewer={true} />;
    if (children.props.moodsViewer) return <AdminView moodsViewer={true} />;

    if (children.props.quizSubmitViewer) return <AdminView quizSubmitViewer={true} />;
    

    if (children.props.inspirationsViewer) return <AdminView inspirationsViewer={true} />;
    if (children.props.treeViewer) return <AdminView treeViewer={true} />;



    if (children.props.engagementsViewer) return <AdminView engagementsViewer={true} />;

    if (children.props.userAnalyticsViewer) return <AdminView userAnalyticsViewer={true} />;

    if (children.props.reportsViewer) return <AdminView reportsViewer={true} />;


    if (children.props.selfAssessmentViewer) return <AdminView selfAssessmentViewer={true} />;


    if (children.props.mxViewer) return <AdminView mxViewer={true} />;
    if (children.props.quizViewer) return <AdminView quizViewer={true} />;
    if (children.props.cashBackViewer) return <AdminView cashBackViewer={true} />;

    if (children.props.cIFViewerRD) return <AdminView cIFViewerRD={true} />;
    if (children.props.applicantViewer) return <AdminView applicantViewer={true} />;
    if (children.props.applicantViewerRD) return <AdminView applicantViewerRD={true} />;
    if (children.props.tradeViewer) return <AdminView tradeViewer={true} />;
    // transaction viewer
    // if (children.props.trasactionViewer) return <AdminView trasactionViewer={true} />;
    if (children.props.transactionviewerRD) return <AdminView transactionviewerRD={true} />;

    if (children.props.timelineViewer) return <AdminView timelineViewer={true} />;
    if (children.props.assessmentViewer) return <AdminView assessmentViewer={true} />;
    if (children.props.addQuiz) return <AdminView addQuiz={true} />;
    if (children.props.addManualQuiz) return <AdminView addManualQuiz={true} />;
    if (children.props.chatRoomsViewer) return <AdminView chatRoomsViewer={true} />;

    return <AdminView dashboardViewer={true} />;
  }

  console.log('User Route...');
  console.log('AUTH PASSED...');
  return children;
}
